import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Calendar from "../components/index/calendar";
import Day from "../components/index/day";
import LayoutStyles from "../components/index/layout.module.scss";
import Metatags from "../components/metatags";
import Logo from "../images/logo.svg";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Metatags title="CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/home.png" url="https://cssgrid31.brett.cool" pathname="/" mdxType="Metatags" />
    <div className={LayoutStyles.container}>
      <div className={LayoutStyles.intro}>
        <img className={LayoutStyles.logo} src={Logo} alt="CSS Grid a Day" title="CSS Grid a Day" />
        <h1>{`CSS Grid a day`}</h1>
        <p>{`An example of using CSS grid every day, running from May`}{` `}{`1–31st,`}{` `}{`2019.`}</p>
        <p>{`A `}<span className={`${LayoutStyles.badge} ${LayoutStyles.complete}`}>{`completed`}</span>{` experiment by `}<a parentName="p" {...{
            "href": "https://twitter.com/brett_jay"
          }}>{`@brett_jay`}</a>{`. All source code is `}<a parentName="p" {...{
            "href": "https://github.com/brettjay/css-grid-a-day"
          }}>{`available on Github`}</a>{`.`}</p>
      </div>
      <Calendar mdxType="Calendar">
  <Day date="29" complete={false} placeholder={true} mdxType="Day" />
  <Day date="30" complete={false} placeholder={true} mdxType="Day" />
  <Day date="1" complete={true} label="📕 Grid systems" color="#FF4A1B" mdxType="Day" />
  <Day date="2" complete={true} label="✍️ Signup form" color="purple" mdxType="Day" />
  <Day date="3" complete={true} label="🎲 Frontend-opoly" color="#71967b" mdxType="Day" />
  <Day date="4" complete={true} label="🗓 Weekly schedule" color="#221d31" mdxType="Day" />
  <Day date="5" complete={true} label="📄 Van de Graaf" color="#164666" mdxType="Day" />
  <Day date="6" complete={true} label="📰 Broadsheet" color="#000" mdxType="Day" />
  <Day date="7" complete={true} label="🖋 Blog composer" color="#559DE6" mdxType="Day" />
  <Day date="8" complete={true} label="✨ Mixin collage" color="#583ca0" mdxType="Day" />
  <Day date="9" complete={true} label="📱 Milligram feed" color="#14546f" mdxType="Day" />
  <Day date="10" complete={true} label="🖥 Dashboard" color="#DA045C" mdxType="Day" />
  <Day date="11" complete={true} label="🎬 Review cards" color="#DB8400" mdxType="Day" />
  <Day date="12" complete={true} label="📋 Todo board" color="#488600" mdxType="Day" />
  <Day date="13" complete={true} label="☕️ Brew guide" color="#6d3f28" mdxType="Day" />
  <Day date="14" complete={true} label="✈️ Seat picker" color="#FF930F" mdxType="Day" />
  <Day date="15" complete={true} label="🎟 Boarding pass" color="#223146" mdxType="Day" />
  <Day date="16" complete={true} label="📈 Brew print" color="#3473B1" mdxType="Day" />
  <Day date="17" complete={true} label="📃 Magazine layout" color="#333" mdxType="Day" />
  <Day date="18" complete={true} label="🗳 Senate ballot" color="#506827" mdxType="Day" />
  <Day date="19" complete={true} label="📱 Itinerary" color="#F26118" mdxType="Day" />
  <Day date="20" complete={true} label="📷 Photoessay" color="#000" mdxType="Day" />
  <Day date="21" complete={true} label="🖥 “Uses”" color="#1c334a" mdxType="Day" />
  <Day date="22" complete={true} label="🔬 Web elements" color="#5a008a" mdxType="Day" />
  <Day date="23" complete={true} label="⚙️ Responsive form" color="#005CDD" mdxType="Day" />
  <Day date="24" complete={true} label="🌦 Wearthere" color="#004D99" mdxType="Day" />
  <Day date="25" complete={true} label="📨 Email client" color="#6177c7" mdxType="Day" />
  <Day date="26" complete={true} label="🗒 Blank canvas anxiety" color="#FC9801" mdxType="Day" />
  <Day date="27" complete={true} label="⌨️ Butterfly keys" color="#333748" mdxType="Day" />
  <Day date="28" complete={true} label="🛒 Ecommerce" color="#940783" mdxType="Day" />
  <Day date="29" complete={true} label="🗺 Melbourne" color="#ffa500" mdxType="Day" />
  <Day date="30" complete={true} label="📝 Journal app" color="#356882" mdxType="Day" />
  <Day date="31" complete={true} label="❤️ Thanks" color="red" mdxType="Day" />
  <Day date="1" complete={false} placeholder={true} mdxType="Day" />
  <Day date="2" complete={false} placeholder={true} mdxType="Day" />
      </Calendar>
    </div>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      